module.exports.APP_VERSION = process.env.VUE_APP_VERSION;
module.exports.DEFAULT_COUNTRY_CODE = "IN";
module.exports.DEFAULT_STATE_CODE = "GJ";
module.exports.CHARGEBEE_SITE = process.env.VUE_APP_CHARGEBEE;
module.exports.CANYONLICENSETYPE = process.env.VUE_APP_CANYONLICENSETYPE;
module.exports.CANYON_IS_SINGLE_APP = process.env.VUE_APP_CANYON_IS_SINGLE_APP;
module.exports.CHARGEBEE_PUBLISHABLE_KEY = process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY;

// API's
module.exports.SEND_MAIL = "/api/v2/sendMail";
module.exports.SEND_INVITATION_EMAIL = "/api/v2/sendInvitationEmail";
module.exports.SEND_VARIFICATION_EMAIL = "/api/v2/sendVerificationEmail";
module.exports.INVITATION_CONFIRMATION = "/api/v2/checkPermission";
module.exports.VERIFY_EMAIL = "/api/v2/verifyEmail";
module.exports.IMPORT_SETTINGS = "/api/v1/importSettings";
module.exports.SEND_NOTIFICATION = "/api/v1/sendNotification";
module.exports.REMOVE_SPRINT_FOLDER = "/api/v1/removeSprintOperations";
module.exports.GET_SUBSCRIPTION_DATA = "/api/v1/getSubscription";
module.exports.DELETE_SUBSCRIPTION = "/api/v1/cancleSubscriptionChargebee";
module.exports.RESTART_SUBSCRIPTION = "/api/v1/removeScheduleCancellationChargebee";
module.exports.GET_INVOICE_PDF = "/api/v1/getInvoicePDF";
module.exports.REACTIVATE_SUBSCRIPTION_CHARGEBEE = "/api/v1/reactivateSubscriptionChargebee";
module.exports.UPDATE_CUSTOMER_BILLING = "/api/v1/updateCustomerBilling";
module.exports.ADD_TIMELOG = "/api/v2/manualLogtime";
module.exports.DELETE_TIMELOG = "/api/v2/deleteManualLogtime";
module.exports.CREATE_COMPANY = "/api/v2/company/create";
module.exports.PREPARE_NOTIFICATION_DATA = "/api/v1/prepare-notification-data";
module.exports.HANDLE_HISTORY = "/api/v1/handleHistory";
module.exports.ADD_MILESTONE = "/api/v1/addmilestone";
module.exports.UPDATE_MILESTONE = "/api/v1/updatemilestone";
module.exports.DELETE_MILESTONE = "/api/v1/deletemilestone";
module.exports.CLEAR_MILESTONE_STATUS = "/api/v1/clearmilestonestatus";
module.exports.CANCEL_MILESTONE_STATUS = "/api/v1/cancelmilestonestatus";
module.exports.REFUND_AMOUNT = "/api/v1/refundamount";
module.exports.DRAGGABLE_MILESTONE = "/api/v1/draggablemilestone";
module.exports.HANDLE_NOTIFICATION = "/api/v1/handleNotification";
module.exports.UPDATA_TASK_INDEX = "/api/v1/taskIndex";
module.exports.ONLOAD_UPDATE_TASK_INDEX = "/api/v1/updateTaskIndexOnload";
module.exports.CREATE_PROJECT = "/api/v1/createProject";
module.exports.WASABI_UPLOAD_FILE = "/api/v1/wasabi/uploadFile";
module.exports.WASABI_RETRIVE_USER_PROFILE = "/api/v1/wasabi/retriveUserProfile";
module.exports.WASABI_RETRIVE_OBJECT = "/api/v1/wasabi/retriveObject";
module.exports.WASABI_DELETE_FILE = "/api/v1/wasabi/deleteFile";
module.exports.CREATE_USER = '/api/v1/createUser';
module.exports.MONGO_OPRATION = '/api/v1/mongoOpration';
module.exports.TASKTYPE = '/api/v1/projectSetting/taskType';
module.exports.TASKSTATUS = '/api/v1/projectSetting/taskStatus';
module.exports.SPRINT = '/api/v1/sprint';
module.exports.FOLDER = '/api/v1/folder';
module.exports.REMOVE_USER_NOTIFICATION = '/api/v1/removeUserNotification';
module.exports.GENERATETOKEN = '/api/v1/generateToken';
module.exports.UPDATE_UNREADREAD_COMMENTS_COUNT = '/api/v1/updateunreadcommentscount';
module.exports.PUSH_NOTIFICATION = '/api/v1/push-notification';
module.exports.V2_TASKS = '/api/v2/tasks';
module.exports.API_URI = process.env.VUE_APP_APIURL;
module.exports.DOMAIN_URI = process.env.VUE_APP_WEBSITEURL;
module.exports.PROJECT_RULES = '/api/v1/importSettingsProjectFunction';
module.exports.GET_CARD_DETAILS = '/api/v2/getCardDetails';
module.exports.CREATE_PAYMENT_SOURCE_CHARGEBEE = '/api/v2/createPaymnetSourceChargebee';
module.exports.CREATE_SUBSCRIPTION_CHARGEBEE = '/api/v2/createSubscriptionChargebee';
module.exports.UPDATECARDFORSUBSCRIPTION= '/api/v1/updateCardForSubscription';
module.exports.CANCELSUBSCRIPTIONPAYMENT = '/api/v1/cancleSubscriptionPayment';
module.exports.REMOVESCHEDULECANCELLATION = '/api/v2/removeScheduleCancellationChargebee';
module.exports.GETINVOICEANDCREDITNOTES = '/api/v1/getInvoiceAndCreditNotes';
module.exports.UPDATESUBSCRIPTIONPAYMENTSTIMATE = '/api/v1/updateSubscriptionPaymentEstimate';
module.exports.UPDATESUBSCRIPTIONPAYMENT = '/api/v1/updateSubscriptionPayment';
module.exports.ADDANDREMOVEUSERFROMSUBSCRIPTIONESTIMATE = '/api/v1/addAndRemoveUserInPaymentSubscriptionEstimate';
module.exports.ADDANDREMOVEUSERFROMSUBSCRIPTION = '/api/v1/addAndRemoveUserInPaymentSubscription';
module.exports.CHECKSENDINVITATION = 'api/v1/checkSendInviatation';
module.exports.CHECKSUBSCRIPTIONSCHEDULE = '/api/v1/checkSubscriptionSchedule';
module.exports.REMOVESUBSCRIPTIONSCHEDULECHANGES = '/api/v1/removeSubscriptionScheduledChanges';
module.exports.PENDING_INVOICE_PAYMENT = '/api/v1/payPendingInvoice';
module.exports.GET_INVOICE_AND_CREDITNOTE_URL = '/api/v1/getInvoiceAndCreditNoteURL';
module.exports.TRACKER_USER_PERMISSION_MANAGEMENT = '/api/v1/manageTrackerUserPermission';
module.exports.IMPORT_NOTIFICATION_SETTING = '/api/v1/importSettingsNotification';
module.exports.GLOBAL_PROJECT_TEMPLATE = '/api/v1/globalProjectTemplate';
module.exports.CANYON_API_URI = process.env.VUE_APP_CANYONAPIURL;
module.exports.CUSTOMER_ADDRESS_ADD_PADDLE = '/api/v1/customerAddressPaddle'
module.exports.GETTRANSECTIONPDFURL = '/api/v1/getTransectionPDFURL';
module.exports.GET_CUSTOMER_CREDIT = '/api/v1/getCustomerCredit';
module.exports.ADD_DEFAULT_SUBSCRIPTION = '/api/v2/addDefaultSubscriptionFun';
module.exports.GETPROMPTS = '/api/v1/getPrompts';
module.exports.FINDONEPROMPTS = '/api/v1/findOnePrompts';
module.exports.GETAICATEGORY = '/api/v1/getAiCategory';
module.exports.USER_STATUS_UPATE = '/api/v1/userStatusUpdate';
module.exports.USER_AND_COMAPNY_CHECK = '/api/v1/userAndCompanyCheck';
module.exports.DELETE_USER_CHAT = '/api/v1/deleteUserChat';
module.exports.SAVE_BRAND_SETTINGS_INFORMATION = '/api/v1/saveBrandSettingsData';
module.exports.GET_BRAND_SETTINGS_INFORMATION = '/api/v1/getBrandSettingsData';
